
<div class="offcanvas offcanvas-end" [ngClass]="width" data-bs-scroll="true" tabindex="-1" [id]="id + '_offcanvas'">
  <div class="offcanvas-header border-bottom">
    <ng-content select="[modal-header]"></ng-content>
  </div>
  <div class="offcanvas-body">
    <ng-content></ng-content>
  </div>
  <div class="offcanvas-footer">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>