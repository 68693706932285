<div id="chart">
  <!-- <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [grid]="chartOptions.grid"
    [stroke]="chartOptions.stroke"
    [colors]="chartOptions.colors"
    [legend]="chartOptions.legend"
    [markers]="chartOptions.markers"
    [title]="chartOptions?.title"
  ></apx-chart> -->

  <apx-chart
  [chart]="chartOptions.chart"
  [annotations]="chartOptions.annotations"
  [series]="chartOptions.series"
  [stroke]="chartOptions.stroke"
  [xaxis]="chartOptions.xaxis"
  [title]="chartOptions.title"
  [subtitle]="chartOptions.subtitle"
  [fill]="chartOptions.fill"
  [tooltip]="chartOptions.tooltip"
  [colors]="chartOptions.colors"
  [grid]="chartOptions.grid"
  [legend]="chartOptions.legend"
  [dataLabels]="chartOptions.dataLabels"
></apx-chart>
</div>
