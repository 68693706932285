import { Component } from '@angular/core';

@Component({
  selector: 'full-loader',
  standalone: true,
  imports: [],
  templateUrl: './full-loader.component.html',
  styleUrl: './full-loader.component.scss'
})
export class FullLoaderComponent {

}
