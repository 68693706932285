import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'support-buttons',
  templateUrl: './support-buttons.component.html',
  styleUrls: ['./support-buttons.component.scss'],
  standalone: true,
  imports: [
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportButtonsComponent implements OnInit {
  cssClass: 'fadeIn' | 'fadeOut' = 'fadeOut';

  ngOnInit(): void {}

  toggleFab() {
    this.cssClass = this.cssClass === 'fadeIn' ? 'fadeOut' : 'fadeIn';
  }
}
