<circle-progress [percent]="percent" [radius]="radius" [space]="spaceStroke" [outerStrokeWidth]="stroke" [innerStrokeWidth]="stroke" [titleFontSize]="fontSize" [animation]="false" [lazy]="false"
[outerStrokeColor]="getColor" [outerStrokeGradientStopColor]="getColor"></circle-progress>

<!-- <div class="circular-progress">
    <div class="progress-ring">
      <div class="progress-circle" [style.transform]="'rotate(' + calculateTransform() + 'deg)'">
        <div class="progress-mask">
          <div class="progress-fill" [style.transform]="'rotate(' + calculateFill() + 'deg)'"></div>
        </div>
      </div>
    </div>
    <div class="progress-text">
      {{ percent }}%
    </div>
  </div> -->