<nav class="navbar fixed-top navbar-expand-lg bg-body-transparent no-shadow">
    <div class="container-fluid">
      <div id="navbarNav" *ngIf="globals.title != 'Login'">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link mt-3" routerLink="/auth/login"
              >Already have an account?
              <span class="text-primary"> Log In</span></a
            >
          </li>
        </ul>
      </div>
      <div id="navbarNav" *ngIf="globals.title == 'Login'">
        <ul class="navbar-nav mt-3">
          <li class="nav-item">
            <a class="nav-link fs-14" aria-current="page" href="#">Products</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-14" aria-current="page" href="#">Blogs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-14" aria-current="page" href="#"
              >Terms & Conditions</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link fs-14" href="#">Privacy Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-14" href="#">Data Policy</a>
          </li>
        </ul>
      </div>
      <a class="nav-link d-flex align-items-center navbar-brand" id="navbarNav">
          <img src="assets/icons/logo_blue.png" class="glow"
              style="width: 40px; height: 40px; margin-right: 0px;" alt="" loading="lazy" />
          <div align="left">
              <p class="mb-0 fs-16" style="margin-top: 0px;">Aide Finance</p>
              <p class="text-primary mb-0 fs-12 fw-4" style="margin-top: -7px;">Partners</p>
          </div>
      </a>
      <!-- <a class="navbar-brand" id="navbarNav">
        <img
          src="https://partners.aidefinance.io/assets/icons/logo_blue.png"
          alt="Logo"
          width="30"
          height="30"
          class="d-inline-block align-text-top"
          style="margin-right: -1px"
        />
        ide Partners
      </a> -->
    </div>
  </nav>

  
<div class="d-flex flex-column vh-100 authlayout">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-5 offset-md-1 my-auto">
                <router-outlet />
            </div>
            <div class="col-md-5 offset-md-1 bg-white vh-100 p-5 myy-auto bg-img"></div>
        </div>
    </div>
</div>

<support-buttons></support-buttons>