import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { GlobalsService } from '@app/core/services';
import { SupportButtonsComponent } from '../../support-buttons/support-buttons.component';

@Component({
  selector: 'auth-layout',
  standalone: true,
  imports: [
    SupportButtonsComponent,
    RouterModule,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss'
})
export class AuthLayoutComponent {
  globals: GlobalsService = inject(GlobalsService);
}
