<nav class="d-lg-flex align-items-end mb-4 sticky-top" *ngIf="!['overview'].includes(title)">
  <h3 class="page-header mb-lg-0 fs-20 bold text-dark">
    {{ title | titlecase }}
    <small class="d-block fs-14 mt-1 text-muted">Monitor key performance metrics for online store</small>
  </h3>

  <div class="ms-auto">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item fs-14"><a href="#">Aide Finance</a></li>
        <li [ngClass]="(breadcrumb) ? '' : 'active'" class="breadcrumb-item fs-14" aria-current="page"> {{ title | titlecase }}</li>
        <li [ngClass]="(breadcrumb) ? 'active' : ''" class="breadcrumb-item fs-14" aria-current="page"> {{ breadcrumb | titlecase }}</li>
      </ol>
    </nav>
  </div>
</nav>