<div class="fadeIn">
  <p>
    Note that these terms and conditions constitute a legal agreement between
    you (the Merchant) and Flutterwave (the Service Provider).
  </p>
  <p>
    You may not access or use any Flutterwave Services unless You Agree to abide
    by all of the terms and conditions as contained in this Agreement
  </p>
  <p>
    Please read the contents of this document carefully and retain a copy for
    your records. Whereas: n app.flutterwave.com This Merchant Service Agreement
    ("Agreement") is a legal agreement between FLUTTERWAVE TECHNOLOGY SOLUTIONS
    LIMITED, a company duly incorporated under the Laws of the Federal Republic
    of Nigeria with its registered address at Block 84, Plot 8, Providence
    Street, Lekki Phase I, Lagos (hereinafter referred to as "Flutterwave" which
    expression shall where the context so admits include its successors-in-title
    and assigns) of the one part;
  </p>
  <p>And</p>
  <p>
    You, (hereinafter referred to as the Merchant) as a user in order for You to
    receive certain payment gateway services and other services offered by
    Flutterwave and or its subsidiaries and affiliates.
  </p>
  <p>
    Flutterwave and Merchant are herein referred to jointly as "Parties" and
    individually as "Party"
  </p>
  <p>
    Flutterwave is a technology company that provides financial, advisory,
    products, services, and technology, as well as transaction processing and
    payment infrastructure to individuals, government and corporate
    organisations across various sectors. via licences and authorisations in
    specific regions or through partnerships with licensed/authorised partners.
  </p>
  <p>
    You may not access or use any Flutterwave Services unless You Agree to abide
    by all of the terms and conditions as contained in this Agreement
  </p>
  <p>
    Please read the contents of this document carefully and retain a copy for
    your records. Whereas: n app.flutterwave.com This Merchant Service Agreement
    ("Agreement") is a legal agreement between FLUTTERWAVE TECHNOLOGY SOLUTIONS
    LIMITED, a company duly incorporated under the Laws of the Federal Republic
    of Nigeria with its registered address at Block 84, Plot 8, Providence
    Street, Lekki Phase I, Lagos (hereinafter referred to as "Flutterwave" which
    expression shall where the context so admits include its successors-in-title
    and assigns) of the one part;
  </p>
  <p>And</p>
  <p>
    You, (hereinafter referred to as the Merchant) as a user in order for You to
    receive certain payment gateway services and other services offered by
    Flutterwave and or its subsidiaries and affiliates.
  </p>
  <p>
    Flutterwave and Merchant are herein referred to jointly as "Parties" and
    individually as "Party"
  </p>
  <p>
    Flutterwave is a technology company that provides financial, advisory,
    products, services, and technology, as well as transaction processing and
    payment infrastructure to individuals, government and corporate
    organisations across various sectors. via licences and authorisations in
    specific regions or through partnerships with licensed/authorised partners.
  </p>

  <div></div>

  <div>
    <input
      class="form-check-input"
      type="checkbox"
      [checked]="ischecked"
      id="agreement"
      (change)="change($event)"
    />
    <label class="form-check-label" for="agreement"> I agree </label>
  </div>
</div>
