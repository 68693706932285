<div class="fadeIn">
  <h4>Complete profile</h4>
  <p>Please fill in the required fields respectively.</p>
  <form class="row g-2 needs-validation" [formGroup]="form" novalidate>
    <div class="col-12 mt-3">
      <p class="mb-2 fs-14">
        <b>Upload Profile </b>
      </p>
      <div
        class="profile-upload position-relative rounded-pill"
        role="button"
        (click)="browseFile()"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          class="position-absolute"
        >
          <path
            d="M16.983 2l1.406 2.109c.371.557.995.891 1.664.891h3.93v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8zm3.07 4c-1.006 0-1.938-.5-2.496-1.337l-1.109-1.663h-6.93l-1.109 1.664c-.557.836-1.49 1.336-2.496 1.336h-4.93v15h22v-15h-2.93zm-7.053 1c3.311 0 6 2.689 6 6s-2.689 6-6 6-6-2.689-6-6 2.689-6 6-6zm0 1c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 2c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm0 1c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-8-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1zm-3-6h3.001v1h-3.001v-1z"
          />
        </svg>
        <img
          class="rounded-pill mb-3"
          [src]="imgURL || form.get('image')?.value"
        />
        <!-- <div class="spinner-container position-absolute">
          <div class="spinner-border text-warning" role="status"></div>
        </div> -->
      </div>

      <div class="ms-4">
        <input
          type="file"
          accept="image/png, image/jpeg"
          style="display: none"
          (change)="handleFile($event)"
          #inputfile
        />
      </div>
    </div>
    <div class="col-6 mb-2 mt-4">
      <p class="mb-2 fs-14">
        <b>Full name</b> <span class="text-danger"> (Required)</span>
      </p>
      <input
        class="form-control form-control-lg"
        placeholder="Enter your full name"
        formControlName="fullname"
      />
      <p
        class="text-danger mb-0 fs-14"
        *ngIf="form.controls.fullname.touched && form.controls.fullname.invalid"
      >
        This field is required
      </p>
    </div>
    <div class="col-6 mb-2 mt-4">
      <p class="mb-2 fs-14">
        <b>Username</b> <span class="text-danger"> (Required)</span>
      </p>
      <input
        class="form-control form-control-lg"
        placeholder="Enter your username"
        formControlName="username"
      />
      <p
        class="text-danger fs-14 mb-0"
        *ngIf="form.controls.username.touched && form.controls.username.invalid"
      >
        This field is required
      </p>
    </div>
    <div class="col-12 mb-2">
      <p class="mb-2 fs-14">
        <b>Sex</b> <span class="text-danger"> (Required)</span>
      </p>
      <select class="form-select form-select-lg" formControlName="sex">
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <p
        class="text-danger fs-14 mb-0"
        *ngIf="form.controls.sex.touched && form.controls.sex.invalid"
      >
        This field is required
      </p>
    </div>

    <div class="col-12">
      <p class="mb-2 fs-14">
        <b>Email address</b>
      </p>
      <input
        id="email"
        type="email"
        class="form-control form-control-lg"
        placeholder="Email"
        formControlName="email"
        readonly
      />
    </div>
  </form>
</div>
