import { Component, Input, inject, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalRightComponent } from '../modals';
import { Select2Module } from 'ng-select2-component';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ 
    NgIf, NgClass, NgFor, TitleCasePipe,
    FormsModule, ReactiveFormsModule, ModalRightComponent, 
    Select2Module 
  ]
})
export class FilterComponent {
  formBuilder: FormBuilder

  constructor() {
    this.formBuilder = inject(FormBuilder);
  }

  @Input() categoryTtype: Array<string> = [];
  @Input() filters: Array<string> = [
    'Approved', 'Date', 'Tenders'
  ];
  @Input() sortLevel: Array<string> = [];
  @Input() isAdvanced: boolean = false;

  @Output() onSelectCategoryType = new EventEmitter();
  @Output() onSelectSortLevel = new EventEmitter();
  @Output() onSort = new EventEmitter();
  @Output() onApply = new EventEmitter();

  search = new FormControl('', Validators.required);
  selectData: any[] = [{
    value: 'One',
    label: 'One',
  },{
    value: 'Two',
    label: 'two',
  },{
    value: 'three',
    label: 'three',
  },{
    value: 'four',
    label: 'four',
  },{
    value: 'five',
    label: 'five',
  },]

  selectType(event: Event) {
    const input = event.target as HTMLInputElement;
    this.onSelectCategoryType.emit(input.value);
  }

  selectLevel(event: Event) {
    const input = event.target as HTMLInputElement;
    this.onSelectSortLevel.emit(input.value);
  }

  sort(event: Event) {
    const input = event.target as HTMLInputElement;
    this.onSort.emit(input.value);
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1)
  }

  private convertArrayToSelectFormat(arr: any[]) {

  }
}
