import { NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from "@angular/core";
import { AgreementComponent } from "@app/modules/onboarding/agreement/agreement.component";
import { CompleteProfileComponent } from "@app/modules/onboarding/complete-profile/complete-profile.component";
import { UploadDocumentsComponent } from "@app/modules/onboarding/upload-documents/upload-documents.component";
import { AccountsService } from "@app/shared";
import { ModalFullscreenComponent } from "@app/shared/components/modals/modal-fullscreen/modal-fullscreen.component";

@Component({
  selector: "app-onboarding-modal",
  standalone: true,
  imports: [
    ModalFullscreenComponent,
    AgreementComponent,
    CompleteProfileComponent,
    UploadDocumentsComponent,
    NgIf,
    NgFor,
  ],
  templateUrl: "./onboarding-modal.component.html",
  styleUrl: "./onboarding-modal.component.scss",
})
export class OnboardingModalComponent implements OnInit {
  changeDetection = inject(ChangeDetectorRef);
  accountService = inject(AccountsService);
  steps = [
    {
      index: 0,
      name: "Agreement",
      invalid: true, // Validation check for enabling and disabling the "next" button for each step
      completed: false,
    },
    {
      index: 1,
      name: "Complete profile",
      invalid: true,
      completed: false,
    },
    {
      index: 2,
      name: "Upload Documents",
      invalid: true,
      completed: false,
    },
  ];

  stepperProgress = 1;
  currentStep: any;
  submitting = false;

  ngOnInit(): void {
    this.updateStepper();
  }

  /** Checks if the current step requirement is fully provided or not */
  validateStep(isValid: boolean) {
    if (isValid) {
      this.currentStep = {
        ...this.currentStep,
        invalid: false,
      };
    } else {
      this.currentStep = {
        ...this.currentStep,
        invalid: true,
      };
    }
  }

  updateStepper() {
    const initialState = this.steps;

    // Checking agreement
    if (this.accountService.scanPreference(["partner_agreement"])) {
      // Update agreement step
      initialState[0].completed = true;
      initialState[0].invalid = false;
    }

    // Checking username
    if (this.accountService.account.username) {
      // Update complete profile step
      initialState[1].completed = true;
      initialState[1].invalid = false;
    }

    // Upload documents step does not need to be checked since it's the last step

    this.steps = initialState;

    this.currentStep = this.steps.find((step) => !step.completed);
    this.stepperProgress = this.currentStep.index + 1;
  }

  async submit() {
    try {
      this.submitting = true;

      switch (this.currentStep.name) {
        case "Agreement":
          if (!this.currentStep.completed)
            await this.accountService.saveAgreement();
          this.nextStep();
          break;
        case "Complete profile":
          const data = JSON.parse(sessionStorage.getItem("profile") as any);
          await this.accountService.editProfile(data);
          this.nextStep();
          break;
        default:
          this.nextStep();
          break;
      }

      this.submitting = false;
      this.changeDetection.detectChanges();
    } catch (e) {
      this.submitting = false;
    }
  }

  nextStep() {
    if (this.stepperProgress < 7) this.stepperProgress += 1;
    this.currentStep = this.steps[this.stepperProgress - 1];
  }

  previousStep() {
    if (this.stepperProgress > 1) this.stepperProgress -= 1;
    this.currentStep = this.steps[this.stepperProgress - 1];
  }
}
