<div class="row mb-3 filter-view no-padding">
  <div class="col-sm-4 d-flex justify-content-end">
    <input type="text" class="form-control" id="exampleInput" placeholder="Type here" [formControl]="search">
  </div>
  <div class="col-sm-6">
    <div class="d-flex flex-wrap">
      <!-- <span *ngFor="let filter of filters;let i = index" class="badge rounded-pill d-flex align-items-center me-2 mb-2">
        {{ filter | titlecase }}
        <i class='bx bx-x fs-18 ms-1 cursor-pointer' (click)="removeFilter(i)"></i>
      </span> -->
    </div>
  </div>
  <div class="col-sm-2">
    <button class="btn btn-sm btn-white shadow-none" data-bs-toggle="offcanvas" data-bs-target="#filter_offcanvas">
      Filter 
      <i class='bx bx-filter'></i>
    </button>
    <button class="btn btn-sm btn-white shadow-none">
      Sort By
      <i class='bx bx-sort-z-a' ></i>
    </button>
  </div>
</div>

<!--  -->

<modal-right [width]="'right-400'" [id]="'filter'">
  <ng-container modal-header>
    <h5 class="offcanvas-title" id="offcanvasLabel">Filter</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </ng-container>
  <div class="">
    <h2 class="fs-16 mb-1 text-dark">Date Range</h2>
    <div class="row mb-3">
      <div class="col-6 mb-2">
        <div class="form-stacked">
          <label class="form-label">From Date</label>
          <input type="date" class="form-control">
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="form-stacked">
          <label class="form-label">To Date</label>
          <input type="date" class="form-control">
        </div>
      </div>
    </div>

    <h2 class="fs-16 mb-1 text-dark">Categories</h2>
    <div class="row mb-1">
      <div class="col-12 mb-1">
        <div class="form-stacked">
          <label class="form-label">Category</label>
          <div class="form-control no-padding">
            <select2 [placeholder]="'Select'" [data]="selectData" multiple="true"></select2>
          </div>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="form-stacked">
          <label class="form-label">Category</label>
          <select class="form-select form-control" (change)="selectType($event)">
            <option selected>Select</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="form-stacked">
          <label class="form-label">Category</label>
          <select class="form-select form-control" (change)="selectType($event)">
            <option selected>Select</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="form-stacked">
          <label class="form-label">Category</label>
          <select class="form-select form-control" (change)="selectType($event)">
            <option selected>Select</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-secondary">Reset All</button>
    <button type="button" class="btn btn-primary shadow-none" style="margin-left: 10px;">Apply</button>
  </div>
</modal-right>