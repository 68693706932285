import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
import { RouterOutlet } from "@angular/router";
import {
  HeaderComponent,
  FooterComponent,
  SidebarComponent,
  BreadcrumbComponent,
} from "@app/shared/components";
import { GlobalsService } from "@app/core/services";
import { SupportButtonsComponent } from "../../support-buttons/support-buttons.component";
import { subscriptionInfo } from "@app/core";
import { OnboardingModalComponent } from "../../../../modules/modals/onboarding-modal/onboarding-modal.component";
import { AccountsService } from "@app/shared/services";

@Component({
  selector: "app-siderbar-layout",
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RouterOutlet,
    SupportButtonsComponent,
    OnboardingModalComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./siderbar-layout.component.html",
  styleUrl: "./siderbar-layout.component.scss",
})
export class SiderbarLayoutComponent implements AfterViewInit {
  subscription: any = subscriptionInfo;

  constructor(
    public globals: GlobalsService,
    public accountService: AccountsService
  ) {}

  ngAfterViewInit(): void {
    const preference = ["partner_agreement", "document_required"];
    const hasAllPreference = this.accountService.scanPreference(preference);
    if (!this.accountService.account.username || !hasAllPreference)
      this.openOnboardingModal();
  }

  openOnboardingModal() {
    document.getElementById("btn-trigger-onboarding")?.click();
  }
}
