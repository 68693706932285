import { LowerCasePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule, NgIf, NgFor, TitleCasePipe, LowerCasePipe
  ]
})
export class SidebarComponent implements AfterViewInit {

  @Input() businessInfo: any;
  @Input() products: any;

  ngAfterViewInit(): void {
  }

  toogleMenu() {
    const myDiv: any = document.getElementById('hasSidebar');
    myDiv.classList.toggle('menuClosed');
  }
}
