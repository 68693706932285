<app-header [title]="globals.title"></app-header>
<app-sidebar
  [products]="subscription?.products || []"
  [businessInfo]="globals.config.business"
></app-sidebar>

<div class="main-content container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-md-12">
      <!-- <div class="col-xl-10 col-xl-11 col-md-12"> -->
      <breadcrumb
        [breadcrumb]="globals.breadcrumb"
        [title]="globals.title"
      ></breadcrumb>
      <router-outlet />
      <support-buttons></support-buttons>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<app-onboarding-modal></app-onboarding-modal>
