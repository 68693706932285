import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'register-layout',
  templateUrl: './register-layout.component.html',
  styleUrls: ['./register-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet
  ],
  standalone: true
})
export class RegisterLayoutComponent {

}
