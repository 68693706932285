<modal-fullscreen id="onboarding" cssClasses="border-0">
  <div class="container modal-body">
    <div class="row pt-5 pb-5">
      <div class="col-12 position-relative">
        <div class="row position-fixed top-0 bg-white w-100 pt-3">
          <div
            class="col-8 pb-3 border-bottom d-flex align-items-center justify-content-end"
          >
            <div class="d-flex align-items-center">
              <div
                class="progress"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div class="progress-bar"></div>
              </div>
              <p class="ms-2 m-0 progress-count">
                {{ stepperProgress }}/{{ steps.length }}
              </p>
            </div>

            <button
              type="button"
              class="ms-5 btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-3 mt-3 position-relative">
        <div class="position-fixed">
          <ul class="stepper rounded-0">
            <li class="step p-0 border-0 mb-4">
              <h6 class="fs-6 fw-bold">Basic information</h6>
              <ul class="list-group mt-3">
                <li
                  class="d-flex align-items-center step p-0 border-0 mb-2"
                  [class.completed]="steps[i].completed"
                  *ngFor="let i of [0, 1, 2]"
                >
                  <span
                    *ngIf="!steps[i].completed && currentStep.index !== i"
                    class="fadeIn"
                  >
                    <svg
                      style="opacity: 0.5"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      fill="#808080"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </span>

                  <span
                    *ngIf="steps[i].completed && currentStep.index !== i"
                    class="fadeIn"
                  >
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      fill="#23c723"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </span>

                  <span *ngIf="currentStep.index === i" class="fadeIn">
                    <svg
                      width="800px"
                      fill="#3b71ca"
                      class="svg-focus"
                      height="800px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                        />
                      </g>
                    </svg>
                  </span>

                  <p
                    class="m-0 ms-2 fs-14"
                    [class.focus]="currentStep.index === i"
                  >
                    {{ steps[i].name }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-6 mt-3 mb-5">
        <div class="body">
          <agreement
            *ngIf="stepperProgress === 1"
            (onCheckboxChange)="validateStep($event)"
          ></agreement>
          <complete-profile
            *ngIf="stepperProgress === 2"
            (formChange)="validateStep($event)"
          ></complete-profile>
          <upload-documents *ngIf="stepperProgress === 3"></upload-documents>
        </div>
      </div>
      <div class="col-12 position-relative">
        <div class="row position-fixed bottom-0 bg-white w-100 pb-3">
          <div class="col-8 pt-3 border-top">
            <div class="d-flex w-100">
              <div>
                <button
                  class="btn bg-none rounded-0 text-primary no-shadow me-3"
                  (click)="previousStep()"
                  [disabled]="submitting"
                >
                  Back
                </button>
                <button
                  class="btn bg-primary text-white no-shadow"
                  (click)="submit()"
                  [disabled]="currentStep.invalid || submitting"
                >
                  <span
                    class="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submitting"
                  ></span>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#onboarding_modal"
    id="btn-trigger-onboarding"
  ></button>
</modal-fullscreen>
