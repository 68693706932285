<div class="splash d-flex justify-content-center align-items-center bg-white">
    <div>
      <a class="nav-link d-flex align-items-center">
          <img src="https://partners.aidefinance.io/assets/icons/logo_blue.png" class="glow"
              style="width: 100px; height: 100px; margin-right: -5px;" alt="" loading="lazy" />
          <div align="left">
              <p class="mb-0 fs-40 text-white" style="margin-top: -5px;">Aide Finance</p>
              <p class="text-light mb-0 fs-18 fw-4" style="margin-top: -15px;">Partners</p>
          </div>
      </a>
    </div>
    <br />
    <h3 class="bottom fs-12 text-white text-center animated fadeIn">Powered by <br /> <span class="fs-18 text-white">Aide Finance Technologies</span></h3>
    <br />
  </div>
  