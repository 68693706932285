
<div class="row">
    <div class="col-md-6 my-auto">
        <div class="d-flex align-items-center justify-content-between w-75 pb-3 mt-5">
            <div class="mt-4">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <img style="width: 350px;" src="assets/gif/nocontent.gif"  />
    </div>
</div>