import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'modal-medium',
  templateUrl: './modal-medium.component.html',
  styleUrls: ['./modal-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass
  ],
})
export class ModalMediumComponent {
  @Input() id: string = 'medium';
}
