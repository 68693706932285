<div
  class="modal fade"
  [id]="id + '_modal'"
  aria-hidden="true"
  aria-labelledby="smallModal"
  tabindex="-1"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
