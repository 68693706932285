<header class="header">
    <nav class="navbar navbar-expand-lg fixed-top no-shadow border-bottom">
        <div class="container-fluid">
            <!-- <a class="navbar-brand">
                {{title | titlecase}}
            </a> -->
            <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent" mdbCollapse>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Link</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li>
                </ul>
            </div> -->

            <form class="d-flex">
                <input class="form-control bg-white me-3 border-1" type="search"
                    placeholder="Find anything on the dashboard here ...." aria-label="Search">
            </form>

            <ul class="navbar-nav mb-2 mb-lg-0 ">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page">
                        <i class="bx bx-bell fs-25"></i>
                        <span class="badge rounded-pill badge-notification bg-danger">1</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" aria-current="page">
                        <i class="bx bx-help-circle fs-25"></i>
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle d-flex align-items-center" id="navbarDropdownMenuLink"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img (31).webp" class="rounded-circle"
                            style="width: 35px; height: 35px; margin-right: 10px;" alt="" loading="lazy" />
                        <div align="left">
                            <p class="mb-0 fw-bold fs-14">CHukwuemeka CHikeluba</p>
                            <p class="text-muted mb-0 fs-12 fw-4" style="margin-top: -5px;">john.doegmail.com</p>
                        </div>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                            <a routerLink="/profile" class="dropdown-item">My profile</a>
                        </li>
                        <li>
                            <a routerLink="/settings" class="dropdown-item">Settings</a>
                        </li>
                        <li>
                            <a routerLink="/auth/login" class="dropdown-item">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</header>