<div class="fab-container hidden-xs">
  <div
    class="fab fab-icon-holder bg-primary"
    role="button"
    (click)="toggleFab()"
  >
    <i class="bx bxs-message-dots fs-30"></i>
  </div>
  <ul class="fab-options" [ngClass]="cssClass">
    <li>
      <span class="fab-label text-dark">Whatsapp</span>
      <a href="https://wa.me/2348093962004?&text=Hi%20PclConsult,%20My%20name%20is%20%27Please%20insert%20your%20fullname%20here%27%20and%20I%20will%20like%20to%20Speak%20with%20an%20Expert%20Consultant." target="_blank" class="fab-icon-holder bg-white">
        <i class="bx bxl-whatsapp text-dark"></i>
      </a>
    </li>
    <li>
      <span class="fab-label text-dark">Request a callback</span>
      <a target="_blank" class="fab-icon-holder bg-white">
        <i class="bx bx-phone text-dark"></i>
      </a>
    </li>
    <li>
      <span class="fab-label text-dark">Live Chat</span>
      <a target="_blank" class="fab-icon-holder bg-white">
        <i class="bx bx-chat text-dark"></i>
      </a>
    </li>
    <li>
      <span class="fab-label text-daark">Send us a mail</span>
      <a target="_blank" class="fab-icon-holder bg-white">
        <i class="bx bx-envelope text-dark"></i>
      </a>
    </li>
  </ul>
</div>
